import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Input, InputNumber, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { displayDurationFromMinutes, displayHoursFromNumber, formatCurrency, nullableDataSorter } from '../../common/utils';
import { calculateGroupSum, formInvoiceFromTimesheetData } from '../../utils';
import { DataItem, GroupByType, GroupedDataItem } from '../../entities';
import { TimesheetSummary } from '../../dal';
import { InvoiceLineItem } from '../../entities/Invoice';
import RoleSelector from './RoleSelector';
import HoursInput from '../Timesheet/HoursInput';
import MoneyInput from '../shared/MoneyInput';
import PhaseSelector from './PhaseSelector';
import { useImmer } from 'use-immer';
import { capitalizeFirstLetter } from '../portfolio/utils';

const { Text } = Typography;

enum LineItemType {
  FIXED = 'fixed',
  HOURLY = 'hourly',
}

type Props = {
  value?: InvoiceLineItem[];
  isClientView?: boolean;
  groupByPhase?: boolean;
  projectId?: string;
  projectBillableRate?: number;
  onUpdateLineItem?: ((lineItem: (DataItem | GroupedDataItem)[]) => void) | null;
  onDeleteLineItem?: (lineItemKey: string) => void;
};

const InvoiceTable = ({
  value = [],
  isClientView = false,
  groupByPhase = false,
  projectId,
  projectBillableRate,
  onUpdateLineItem,
  onDeleteLineItem,
}: Props) => {
  const [groupingOption, setGroupingOption] = useState<GroupByType>(isClientView ? 'category' : '');

  const formattedInvoice = useMemo(
    () =>
      isClientView
        ? formInvoiceFromTimesheetData(value, groupingOption).filter(
            detail =>
              (detail.isGroup && (detail.billableTotal || detail.fixedBillableTotal) && !detail.key.includes('Undefined')) ||
              (detail.isLineItem &&
                ((groupingOption === 'category' && (detail.category === 'Undefined' || detail.category === undefined)) ||
                  (groupingOption === 'phaseName' && (detail.phaseName === 'Undefined' || detail.phaseName === undefined)))),
          )
        : formInvoiceFromTimesheetData(value, groupingOption),
    [value, groupingOption, isClientView],
  );

  const defineCustomValues = useCallback((items: (DataItem | GroupedDataItem)[]) => {
    return Object.fromEntries(items.map(item => [item.key, item]));
  }, []);

  const [customValues, setCustomValues] = useImmer<Record<string, DataItem | GroupedDataItem>>(defineCustomValues(formattedInvoice));

  const [globalBillableRate, setGlobalBillableRate] = useState<number>(projectBillableRate || 0);

  const updateCustomValue = useCallback(
    (key: string, field: string, value: any) => {
      setCustomValues(draft => {
        draft[key] ??= { key, billableHours: 0, billableRate: 0, billableTotal: 0 };
        draft[key][field] = value;

        if (key.endsWith('lineItem')) {
          draft[key]['isLineItem'] = true;
        }

        if (!key.endsWith('fixedCost') && !key.endsWith('commission')) {
          draft[key]['billableTotal'] = (draft[key]['billableHours'] || 0) * (draft[key]['billableRate'] || 0);
        }

        onUpdateLineItem?.([{ ...draft[key] }]);
      });
    },
    [onUpdateLineItem, setCustomValues],
  );

  useEffect(() => {
    setCustomValues(defineCustomValues(formattedInvoice));
  }, [defineCustomValues, formattedInvoice, setCustomValues]);

  const handleGlobalBillableRateChange = useCallback(
    value => {
      setGlobalBillableRate(value ?? 0);

      const updatedItems: (DataItem | GroupedDataItem)[] = [];

      setCustomValues(draft => {
        Object.keys(draft).forEach(key => {
          if (!key.endsWith('lineItem')) {
            draft[key]['billableRate'] = value;
          }

          if (!key.endsWith('fixedCost') && !key.endsWith('commission')) {
            draft[key]['billableTotal'] = (draft[key]['billableHours'] || 0) * (draft[key]['billableRate'] || 0);
          }

          updatedItems.push({ ...draft[key] });
          onUpdateLineItem?.(updatedItems);
        });
      });
    },
    [onUpdateLineItem, setCustomValues],
  );

  const addRow = useCallback(() => {
    const key = `${Date.now()}_lineItem`;
    const newLineItem = {
      key: key,
      category: 'Undefined',
      phaseName: 'Undefined',
      isLineItem: true,
    };

    setCustomValues(draft => {
      draft[key] = newLineItem;
    });
  }, [setCustomValues]);

  const deleteRow = (deletingRowKey: string) => {
    setCustomValues(draft => {
      delete draft[deletingRowKey];
    });

    onDeleteLineItem?.(deletingRowKey);
  };

  const summary = pageData => {
    let summaryBillableHourlySpent = 0;
    let summaryTotalMinutes = 0;
    let summaryBillableHours = 0;
    let summaryHourlySpent = 0;
    let summaryFixedSpent = 0;
    let summaryBillableFixedSpent = 0;
    let summaryBillableCount = 0;

    pageData.forEach(item => {
      const {
        key,
        totalMinutes = 0,
        totalCost = 0,
        fixedCost = 0,
        billableTotal = 0,
        billableHours = 0,
        isGroup,
        fixedBillableTotal = 0,
        isCommission,
        type,
        isLineItem,
      } = item;

      const customValue = customValues[key] || {};

      if (isClientView && (isGroup || isLineItem)) {
        summaryBillableHourlySpent += billableHours && billableTotal ? billableTotal : 0;
        summaryBillableFixedSpent += !billableTotal ? fixedBillableTotal : 0;
        summaryBillableHours += !fixedBillableTotal && type !== 'fixed' && billableHours;
        summaryBillableCount += (fixedBillableTotal || type === 'fixed') && billableHours;
      } else if (!isClientView && !isGroup) {
        const customBillableHours = customValue.billableHours || 0;

        summaryBillableHourlySpent += customBillableHours ? customValue.billableTotal || 0 : 0;
        summaryTotalMinutes += totalMinutes;
        summaryBillableHours += type !== 'fixed' ? customBillableHours : 0;
        summaryBillableCount += type === 'fixed' ? customBillableHours : 0;

        summaryHourlySpent += fixedCost ? 0 : totalCost;
        summaryFixedSpent += isCommission ? 0 : fixedCost;
        summaryBillableFixedSpent += !customBillableHours
          ? customValue.fixedBillableTotal ?? ((!customValue.isCommission && customValue.fixedCost) || 0)
          : 0;
      }
    });

    return isClientView ? (
      <Table.Summary.Row className="role-group-row">
        <Table.Summary.Cell index={0} colSpan={3}>
          <Text>Totals</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="right">
          <Text>
            {summaryBillableHours ? displayDurationFromMinutes(Math.round(summaryBillableHours * 60)) : ''}{' '}
            {summaryBillableHours && summaryBillableCount ? ' / ' : ''}
            {summaryBillableCount ? summaryBillableCount : ''}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="right"></Table.Summary.Cell>
        <Table.Summary.Cell index={3} align="right">
          <Text>{formatCurrency(summaryBillableHourlySpent + summaryBillableFixedSpent)}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ) : (
      <>
        <Table.Summary.Row className="role-group-row">
          <Table.Summary.Cell index={0} colSpan={groupingOption ? 5 : 6}>
            <Text>Totals</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            <Text>{displayDurationFromMinutes(summaryTotalMinutes)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="right">
            <Text>{formatCurrency(summaryHourlySpent + summaryFixedSpent)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="right">
            <Text>
              {summaryBillableHours ? displayDurationFromMinutes(Math.round(summaryBillableHours * 60)) : ''}
              {summaryBillableHours && summaryBillableCount ? ' / ' : ''}
              {summaryBillableCount || ''}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5} align="right">
            <Text>{formatCurrency(summaryBillableHourlySpent + summaryBillableFixedSpent)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row className="role-group-row">
          <Table.Summary.Cell index={0} colSpan={groupingOption ? 9 : 10} align="right">
            <Text>Markup</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            <Text>
              {summaryBillableHourlySpent || summaryBillableFixedSpent
                ? ((1 - (summaryHourlySpent + summaryFixedSpent) / (summaryBillableHourlySpent + summaryBillableFixedSpent)) * 100).toFixed(2) + '%'
                : '0%'}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="right"></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const rowClassName = record => (!isClientView && record.isGroup ? 'role-group-row' : '');

  const baseOptions = [
    { label: 'Group by role', value: 'category' },
    { label: 'Group by phase', value: 'phaseName' },
  ];

  const options: { label: string; value: string }[] = [];

  if (isClientView) {
    if (groupByPhase) {
      options.push(...baseOptions);
    }
  } else {
    options.push({ label: 'Ungrouped', value: '' }, ...baseOptions);
  }

  const renderNameValue = (key: string, { isLineItem }: TimesheetSummary) => {
    if (isClientView && isLineItem) {
      return <Text>{customValues[key]?.lineItemName}</Text>;
    }

    if (!isClientView && isLineItem) {
      return (
        <Input placeholder="Name" value={customValues[key]?.lineItemName} onChange={e => updateCustomValue(key, 'lineItemName', e.target.value)} />
      );
    }

    return <Text>Project services</Text>;
  };

  const renderTypeValue = (key: string, { isLineItem, isCommission, isGroup, fixedCost, type }: TimesheetSummary) => {
    if (isLineItem) {
      if (isClientView) {
        return <Text italic>{capitalizeFirstLetter(customValues[key]?.type || '')}</Text>;
      }

      return (
        <Select placeholder="Type" value={customValues[key]?.type} onChange={value => updateCustomValue(key, 'type', value)}>
          <Select.Option value={LineItemType.FIXED}>Fixed</Select.Option>
          <Select.Option value={LineItemType.HOURLY}>Hourly</Select.Option>
        </Select>
      );
    }

    if (isCommission) {
      return <Text delete>Commission</Text>;
    }

    if ((isGroup && type === 'fixed') || (!isGroup && fixedCost)) {
      return <Text italic>Fixed</Text>;
    }

    return <Text italic>Hourly</Text>;
  };

  const renderResourceValue = (value: string, { key, isGroup, isCommission, isLineItem }: TimesheetSummary) => {
    if (isLineItem && !isClientView) {
      if (groupingOption === 'category') {
        return renderRoleValue(value, { key, isCommission, isLineItem } as TimesheetSummary);
      }

      if (groupingOption === 'phaseName') {
        return renderPhaseValue(value, { key, isCommission, isLineItem } as TimesheetSummary);
      }
    }

    if (isGroup) {
      return key.endsWith(' (fixed cost)') ? key.replace(' (fixed cost)', '') : key;
    }

    if (isCommission) {
      return <Text delete>{value}</Text>;
    }

    return isClientView ? value ?? 'Undefined' : value;
  };

  const renderRoleValue = (value: string, { key, isCommission, isLineItem }: TimesheetSummary) => {
    if (isCommission) {
      return <Text delete>{value}</Text>;
    }

    if (isLineItem) {
      return (
        <RoleSelector
          style={{ maxWidth: '145px' }}
          value={customValues[key]?.category}
          onChange={value => updateCustomValue(key, 'category', value)}
        />
      );
    }

    return value;
  };

  const renderPhaseValue = (value, { key, isCommission, isLineItem }: TimesheetSummary) => {
    if (isCommission) {
      return <Text delete>{value}</Text>;
    }

    if (isLineItem) {
      return (
        <PhaseSelector
          projectid={projectId}
          isLineItem
          style={{ maxWidth: '145px' }}
          value={customValues[key]?.phaseName}
          onChange={value => updateCustomValue(key, 'phaseName', value)}
        />
      );
    }

    return value;
  };

  const renderTotalMinutesValue = (value, { newTotalMinutes }: TimesheetSummary) => {
    if (value !== undefined) {
      if (newTotalMinutes !== undefined) {
        return (
          <Space direction="vertical">
            <Text type="danger">{displayDurationFromMinutes(value)}</Text>
            <Text type="success">{displayDurationFromMinutes(newTotalMinutes)}</Text>
          </Space>
        );
      }

      return displayDurationFromMinutes(value);
    }

    return '';
  };

  const renderTotalCostValue = (value, { isGroup, isCommission, fixedCost, newTotalCost }: TimesheetSummary) => {
    if (!isGroup && isCommission) {
      return <Text delete>{formatCurrency(value)}</Text>;
    }

    if (value !== undefined) {
      if (newTotalCost !== undefined) {
        return (
          <Space direction="vertical">
            <Text type="danger">{formatCurrency(value || fixedCost)}</Text>
            <Text type="success">{formatCurrency(newTotalCost)}</Text>
          </Space>
        );
      }

      return formatCurrency(value || fixedCost);
    }

    return '';
  };

  const renderBillableHoursValue = (value, { key, isLineItem, isGroup, fixedBillableTotal, fixedCost }: TimesheetSummary) => {
    if (isClientView) {
      if (customValues[key]?.billableHours && customValues[key]?.type === 'hourly') {
        return displayHoursFromNumber(customValues[key]?.billableHours || 0);
      }

      if (customValues[key]?.billableHours) {
        return <Text>{customValues[key]?.billableHours}</Text>;
      }

      return '';
    }

    if (isLineItem) {
      const type = customValues[key]?.type;

      return type === LineItemType.FIXED ? (
        <InputNumber
          controls={false}
          style={{ width: '75px' }}
          value={customValues[key]?.billableHours}
          onChange={hours => updateCustomValue(key, 'billableHours', hours || 0)}
        />
      ) : (
        <HoursInput
          style={{ width: '75px' }}
          value={customValues[key]?.billableHours}
          onChange={(_, hoursNumber) => updateCustomValue(key, 'billableHours', hoursNumber || 0)}
        />
      );
    }

    if (isGroup && fixedBillableTotal) {
      return <Text>{calculateGroupSum(customValues, key, 'count') || ''}</Text>;
    }

    if (isGroup) {
      return <HoursInput readOnly value={calculateGroupSum(customValues, key, 'hours')} />;
    }

    return (
      <HoursInput
        readOnly={!!fixedCost}
        value={!fixedCost ? customValues[key]?.billableHours : ''}
        onChange={(_, number) => updateCustomValue(key, 'billableHours', number || 0)}
      />
    );
  };

  const renderBillableRateValue = (value: number, { key, isLineItem, fixedCost, isGroup }: TimesheetSummary) => {
    if (isClientView) {
      return customValues[key] && customValues[key].billableTotal && customValues[key].billableHours
        ? formatCurrency((customValues[key].billableTotal || 0) / (customValues[key].billableHours || 1))
        : '';
    }

    if (!fixedCost && !isGroup) {
      return (
        <MoneyInput value={customValues[key]?.billableRate} onChange={customValue => updateCustomValue(key, 'billableRate', customValue || 0)} />
      );
    }

    if (isLineItem) {
      return (
        <InputNumber
          controls={false}
          style={{ width: '75px' }}
          value={customValues[key]?.billableRate}
          onChange={value => updateCustomValue(key, 'billableRate', value || 0)}
        />
      );
    }
  };

  const renderBillableTotalValue = (
    value,
    { key, fixedBillableTotal, isLineItem, isCommission, fixedCost, isGroup, totalCost }: TimesheetSummary,
  ) => {
    if (isClientView) {
      return formatCurrency(customValues[key]?.fixedBillableTotal || customValues[key]?.billableTotal || 0);
    }

    if (isCommission) {
      return <Text delete>{formatCurrency(value || fixedBillableTotal || 0)}</Text>;
    }

    if (isLineItem) {
      return <MoneyInput className="invoice-line-item-amount" readOnly value={customValues[key]?.billableTotal} />;
    }

    if (isGroup && fixedBillableTotal) {
      return formatCurrency(calculateGroupSum(customValues, key, 'fixed'));
    }

    if (isGroup && !fixedBillableTotal) {
      return formatCurrency(calculateGroupSum(customValues, key, 'spend'));
    }

    if (fixedCost) {
      return (
        <MoneyInput
          value={customValues[key]?.fixedBillableTotal}
          onChange={customValue => updateCustomValue(key, 'fixedBillableTotal', customValue || 0)}
        />
      );
    }

    return formatCurrency(totalCost ? (customValues[key]?.billableHours || 0) * (customValues[key]?.billableRate || 0) : fixedCost);
  };

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: '1rem' }}>
      <Radio.Group options={options} onChange={e => setGroupingOption(e.target.value)} value={groupingOption} optionType="button" />

      <Table dataSource={Object.values(customValues)} pagination={false} size="small" summary={summary} rowKey="key" rowClassName={rowClassName}>
        <Table.Column
          title="Name"
          dataIndex="key"
          width="14%"
          render={(value: string, record: TimesheetSummary) => renderNameValue(value, record)}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.lineItemName, b.lineItemName))
          }
        />

        <Table.Column
          title="Type"
          dataIndex="key"
          width="8%"
          render={(value: string, record: TimesheetSummary) => renderTypeValue(value, record)}
          sorter={(!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.type, b.type))}
        />

        <Table.Column
          title={isClientView ? (groupingOption === 'category' ? 'Role' : 'Phase') : 'Resource'}
          dataIndex="userName"
          width="12%"
          render={(value, record: TimesheetSummary) => renderResourceValue(value, record)}
          sorter={
            (!groupingOption || isClientView) && ((a, b) => nullableDataSorter(isClientView ? a.key : a.userName, isClientView ? b.key : b.userName))
          }
        />

        {!isClientView && (
          <Table.Column
            title={groupingOption === 'category' ? 'Phase' : 'Role'}
            dataIndex={groupingOption === 'category' ? 'phaseName' : 'category'}
            width="12%"
            render={(value, record: TimesheetSummary) =>
              groupingOption === 'category' ? renderPhaseValue(value, record) : renderRoleValue(value, record)
            }
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.category, b.category))}
          />
        )}

        {!groupingOption && (
          <Table.Column
            title="Phase"
            dataIndex="phaseName"
            width="12%"
            render={(value, record: TimesheetSummary) => renderPhaseValue(value, record)}
            sorter={
              !groupingOption &&
              ((a: TimesheetSummary, b: TimesheetSummary) => {
                return nullableDataSorter(a.phaseName, b.phaseName);
              })
            }
          />
        )}

        {!isClientView && (
          <Table.Column
            title="Hourly rate"
            dataIndex="ratePerHour"
            align="right"
            width="5%"
            render={(value, { isGroup, fixedCost }: TimesheetSummary) => (!isGroup && !fixedCost && value !== undefined ? formatCurrency(value) : '')}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.ratePerHour, b.ratePerHour))}
          />
        )}

        {!isClientView && (
          <Table.Column
            title="Real hours"
            dataIndex="totalMinutes"
            width="5%"
            render={(value, record: TimesheetSummary) => renderTotalMinutesValue(value, record)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalMinutes, b.totalMinutes))}
            align="right"
          />
        )}

        {!isClientView && (
          <Table.Column
            title="Real spent"
            dataIndex="totalCost"
            align="right"
            width="8%"
            render={(value, record: TimesheetSummary) => renderTotalCostValue(value, record)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalCost, b.totalCost))}
          />
        )}

        <Table.Column
          title="Hours / Count"
          dataIndex="billableHours"
          align="right"
          width="7%"
          render={(value, record: TimesheetSummary) => renderBillableHoursValue(value, record)}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableHours, b.billableHours))
          }
        />

        <Table.Column
          title={
            isClientView ? (
              'Rate / Price'
            ) : (
              <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'rigth', justifyContent: 'end' }}>
                <div>Rate / Price</div>

                <MoneyInput value={globalBillableRate ?? 0} onChange={handleGlobalBillableRateChange} />
              </div>
            )
          }
          dataIndex="billableRate"
          align="right"
          width="8%"
          render={(value, record: TimesheetSummary) => renderBillableRateValue(value, record)}
        />

        <Table.Column
          title={isClientView ? 'Total' : 'Billable spend'}
          dataIndex="billableTotal"
          align="right"
          width="8%"
          render={(value, record: TimesheetSummary) => renderBillableTotalValue(value, record)}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableTotal, b.billableTotal))
          }
        />

        {!isClientView && (
          <Table.Column
            align="right"
            width="5%"
            render={(_, { isLineItem, key }: TimesheetSummary) =>
              isLineItem && (
                <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(key)}>
                  <Button type="text" icon={<MinusCircleOutlined />} />
                </Popconfirm>
              )
            }
          />
        )}
      </Table>

      {!isClientView && (
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={24}>
            <Button type="dashed" onClick={addRow} style={{ marginBottom: 16 }} icon={<PlusOutlined />} block>
              Add Line Item
            </Button>
          </Col>
        </Row>
      )}
    </Space>
  );
};

export default InvoiceTable;
