import { Tag } from 'antd';
import { getInvoiceRowCssClass, Invoice } from '../../entities/Invoice';

type Props = {
  invoice: Invoice;
};

export const InvoiceStatusTag = ({ invoice }: Props) => {
  const statusColor = getInvoiceRowCssClass(invoice);
  return (
    <Tag className={`status-tag ${statusColor}`} style={{ textTransform: 'capitalize' }}>
      {statusColor.replace('-invoice', '')}
    </Tag>
  );
};
