import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Form, List, message, PageHeader, Select, SelectProps, Space, Statistic, Table, Typography } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';

import moment from 'moment';
import { Link } from 'react-router-dom';

import TimesheetTabsControls from '../../components/Timesheet/TimesheetTabsControls';
import { useCommissionReport } from '../../dal/useCommissionReport';
import { CommissionReportItem, CreateCommissionTimesheetEntry } from '../../entities';
import { formatCurrency, nullableDataSorter } from '../../common/utils';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import ProjectsCommissionModal from './CommissionReport.commissionModal';
import { useSession } from '../../dal';
import { InvoiceStatusTag } from '../../components/project/InvoiceStatusTag';
import { Invoice } from '../../entities/Invoice';
import { submitCommissionTimesheet } from '../../dal/submitTimesheet';

const CommissionReport = () => {
  const { ability } = usePermissions();
  const { session } = useSession();

  const [approvalForm] = Form.useForm();

  const [activeDay, setActiveDay] = useState(moment().startOf('month'));
  const [openCommission, setOpenCommission] = useState<boolean>(false);
  const [selectedPerson, setSelectedPerson] = useState<string | null>(null);
  const [isApprovalStarted, setIsApprovalStarted] = useState<boolean>(false);

  const {
    commissionReportForMonth,
    totalCommissionAmountYearAll,
    totalCommissionAmountMonthAll,
    isLoading: commissionReportForMonthLoading,
    mutate: commissionReportMutate,
  } = useCommissionReport(activeDay.format('YYYY-MM-DD'));

  const getPreviousMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().subtract(1, 'month'));
  }, []);

  const getNextMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().add(1, 'month'));
  }, []);

  const personOptions: SelectProps['options'] = useMemo(
    () =>
      Array.from(new Set(commissionReportForMonth?.map(report => report.personName))).map(person => ({
        key: person,
        label: person,
        value: person,
      })),
    [commissionReportForMonth],
  );

  useEffect(() => {
    if (personOptions.length === 0) {
      setSelectedPerson(null);
    }
  }, [personOptions.length]);

  const filteredReports = useMemo(() => {
    if (!selectedPerson) {
      return commissionReportForMonth;
    }

    return commissionReportForMonth?.filter(report => report.personName === selectedPerson);
  }, [selectedPerson, commissionReportForMonth]);

  const filteredTotalCommissionAmountYearAll = useMemo(() => {
    if (!selectedPerson) {
      return totalCommissionAmountYearAll?.total;
    }

    return totalCommissionAmountYearAll?.persons.find(report => report.personName === selectedPerson);
  }, [selectedPerson, totalCommissionAmountYearAll]);

  const filteredTotalCommissionAmountMonthAll = useMemo(() => {
    if (!selectedPerson) {
      return totalCommissionAmountMonthAll?.total;
    }

    return totalCommissionAmountMonthAll?.persons.find(report => report.personName === selectedPerson);
  }, [selectedPerson, totalCommissionAmountMonthAll]);

  const getInitialValues = useCallback(
    (reports: CommissionReportItem[]) => {
      const getInvoiceDate = (invoice: Invoice) => {
        if (invoice?.approveDate) return moment(invoice.approveDate);
        if (invoice?.paidDate && ability.can('update', 'CommissionApprove')) return moment();
        return null;
      };

      const mapInvoices = invoices =>
        invoices.reduce((invoiceAcc, invoice) => {
          if (invoice?.id) {
            invoiceAcc[invoice.id] = getInvoiceDate(invoice);
          }
          return invoiceAcc;
        }, {});

      const initialValues = {
        rows: reports?.reduce((acc, row) => {
          acc[row.key] = { invoices: mapInvoices(row.invoice) };
          return acc;
        }, {}),
      };

      return initialValues;
    },
    [ability],
  );

  useEffect(() => {
    if (commissionReportForMonth) {
      approvalForm.setFieldsValue(getInitialValues(commissionReportForMonth));
    }
  }, [commissionReportForMonth, approvalForm, getInitialValues]);

  const handleApprove = useCallback(
    async (row: CommissionReportItem, invoice: Invoice) => {
      if (isApprovalStarted) return;

      setIsApprovalStarted(true);

      try {
        const selectedDate = approvalForm.getFieldValue(['rows', row.key, 'invoices', invoice.id]);

        const timesheet: CreateCommissionTimesheetEntry = {
          project: row.projectId,
          description: invoice.invoiceNumber,
          entryDate: selectedDate,
          fixedCost: invoice.commissionAmount || 0,
          isCommission: true,
          user: row.userId,
          invoiceId: invoice.id,
        };

        await submitCommissionTimesheet(timesheet);
        await commissionReportMutate();

        message.success('This invoice has been approved');
      } catch (error: any) {
        console.error('Approval failed:', error);

        const errorMessage = error?.response?.data?.message || error.message || 'Unknown error occurred';
        message.error(`Failed to approve this invoice. ${errorMessage}`);
      } finally {
        setIsApprovalStarted(false);
      }
    },
    [isApprovalStarted, approvalForm, commissionReportMutate],
  );

  const summary = useCallback(pageData => {
    const calculateTotal = (data: typeof pageData, field: 'value' | 'commissionAmount') =>
      data.reduce((total, { invoice }) => total + invoice.reduce((sum, item) => sum + item[field], 0), 0);

    const summaryBillAmount = calculateTotal(pageData, 'value');
    const summaryCommissionAmount = calculateTotal(pageData, 'commissionAmount');

    return (
      <>
        <Table.Summary.Row className="role-group-row">
          <Table.Summary.Cell index={0} colSpan={6}>
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            {formatCurrency(summaryBillAmount)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="right">
            {formatCurrency(summaryCommissionAmount)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }, []);

  const getButtonStyle = useCallback((isApproved: boolean) => {
    if (isApproved) {
      return {
        background: '#e2facf',
        color: '#389e0d',
        cursor: 'not-allowed',
      };
    }
  }, []);

  const getButtonText = useCallback((isApproved: boolean, isPaid: boolean, canApprove: boolean) => {
    if (isApproved) return 'Approved';
    if (isPaid && canApprove) return 'Approve';
    if (isPaid && !canApprove) return 'Not approved';
    return 'Not paid';
  }, []);

  const renderInvoiceButton = useCallback(
    (invoice: Invoice, row: CommissionReportItem) => {
      const isPaid = !!invoice?.paidDate;
      const ableApprove = ability.can('update', 'CommissionApprove');
      const isApproved = isPaid && invoice?.isApproved;
      const canApprove = isPaid && !isApproved && ableApprove;

      const buttonStyle = getButtonStyle(!!isApproved);
      const buttonText = getButtonText(!!isApproved, isPaid, ableApprove);

      return (
        <List.Item style={{ justifyContent: 'center' }}>
          <Button
            style={buttonStyle}
            type="primary"
            onClick={() => canApprove && handleApprove(row, invoice)}
            disabled={!canApprove || isApprovalStarted}
          >
            {buttonText}
          </Button>
        </List.Item>
      );
    },
    [ability, getButtonStyle, getButtonText, handleApprove, isApprovalStarted],
  );

  const renderInvoiceDatePicker = (invoice: Invoice, row: CommissionReportItem) => {
    const isActive = invoice?.paidDate && !invoice?.isApproved && ability.can('update', 'CommissionApprove');

    const isApproved = invoice?.paidDate && invoice?.isApproved;

    const approvedStyle = {
      background: '#e2facf',
      color: '#389e0d',
      cursor: 'not-allowed',
      margin: 0,
    };

    const style = isApproved ? approvedStyle : { margin: 0 };

    return (
      <List.Item>
        {row.key && invoice.id && (
          <Form.Item name={['rows', row.key, 'invoices', invoice.id]} rules={[{ required: true, message: 'Please select a date!' }]} style={style}>
            <DatePicker disabled={!isActive} />
          </Form.Item>
        )}
      </List.Item>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={activeDay.format('MMMM, YYYY')}
        className="payroll-header"
        subTitle={<TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />}
        extra={
          <Space>
            {personOptions.length > 1 && (
              <Select placeholder="Filter by person" options={personOptions} allowClear onChange={value => setSelectedPerson(value)} />
            )}

            {ability.can('update', 'Commission') && (
              <Button icon={<PercentageOutlined />} onClick={() => setOpenCommission(true)}>
                Add commission
              </Button>
            )}
          </Space>
        }
      />

      <div className="ant-space-vertical">
        <Form form={approvalForm}>
          <Table
            loading={commissionReportForMonthLoading || isApprovalStarted}
            bordered
            size="small"
            dataSource={filteredReports}
            rowKey="key"
            pagination={false}
            summary={summary}
            scroll={{ x: 1150 }}
            title={() => (
              <Space className="flex-wrap">
                <Statistic
                  title="Total Year Billable Amount"
                  value={filteredTotalCommissionAmountYearAll?.billAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
                <Statistic
                  title="Total Month Billable Amount"
                  value={filteredTotalCommissionAmountMonthAll?.billAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />

                <Statistic
                  title="Total Year Commission Amount"
                  value={filteredTotalCommissionAmountYearAll?.commissionAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />

                <Statistic
                  title="Total Year Commission Amount Approved"
                  value={filteredTotalCommissionAmountYearAll?.commissionAmountApproved}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />

                <Statistic
                  title="Total Month Commission Amount"
                  value={filteredTotalCommissionAmountMonthAll?.commissionAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />

                <Statistic
                  title="Total Month Commission Amount Approved"
                  value={filteredTotalCommissionAmountMonthAll?.commissionAmountApproved}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
              </Space>
            )}
          >
            <Table.Column
              dataIndex="clientName"
              title="Client"
              render={(value, row) => (
                <Link onClick={event => event.stopPropagation()} to={`/clients/${row.clientId}`} rel="noopener noreferrer" target="_blank">
                  {value}
                </Link>
              )}
              sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.clientName, b.clientName)}
            />
            <Table.Column
              dataIndex="projectName"
              title="Project"
              render={(value, row) => (
                <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                  {value}
                </Link>
              )}
              sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.projectName, b.projectName)}
            />
            <Table.Column
              dataIndex="projectCode"
              title="Code"
              render={(value, row) => (
                <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                  {value}
                </Link>
              )}
              sorter={(a: CommissionReportItem, b: CommissionReportItem) =>
                nullableDataSorter(parseInt(a.projectCode.substring(1), 10), parseInt(b.projectCode.substring(1), 10))
              }
            />
            <Table.Column
              dataIndex="personName"
              title="Person"
              render={(value, row) => (
                <Link onClick={event => event.stopPropagation()} to={`/users/${row.userId}`} rel="noopener noreferrer" target="_blank">
                  {value}
                </Link>
              )}
              sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.personName, b.personName)}
            />
            <Table.Column
              title="Invoice Number"
              render={(_, row: CommissionReportItem) => (
                <List
                  size="small"
                  dataSource={row.invoice}
                  renderItem={invoice => (
                    <List.Item style={{ padding: '16px 0' }}>
                      {ability.can('view', 'Invoice') ? (
                        <Link
                          onClick={event => event.stopPropagation()}
                          to={`/projects/${row.projectId}/invoices/${invoice.id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {invoice.invoiceNumber}
                        </Link>
                      ) : (
                        <Typography.Text>{invoice.invoiceNumber}</Typography.Text>
                      )}
                    </List.Item>
                  )}
                />
              )}
            />
            <Table.Column
              title="Status"
              render={(_, row: CommissionReportItem) => (
                <List
                  size="small"
                  dataSource={row.invoice}
                  renderItem={invoice => (
                    <List.Item style={{ padding: '16px 0' }}>
                      <InvoiceStatusTag invoice={invoice} />
                    </List.Item>
                  )}
                />
              )}
              sorter={(a: CommissionReportItem, b: CommissionReportItem) => {
                const aStatus = getStatusRank(a.invoice?.[0]);
                const bStatus = getStatusRank(b.invoice?.[0]);

                return aStatus - bStatus;
              }}
            />
            <Table.Column
              dataIndex="billAmount"
              title="Bill Amount"
              align="right"
              render={(_, row: CommissionReportItem) => (
                <List
                  size="small"
                  dataSource={row.invoice}
                  renderItem={invoice => (
                    <List.Item style={{ padding: '16px 0', justifyContent: 'end' }}>
                      <Typography.Text>{formatCurrency(invoice.value)}</Typography.Text>
                    </List.Item>
                  )}
                />
              )}
              sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.invoice[0].value, b.invoice[0].value)}
            />
            <Table.Column
              dataIndex="commissionAmount"
              title="Commission"
              align="right"
              render={(_, row: CommissionReportItem) => (
                <List
                  size="small"
                  dataSource={row.invoice}
                  renderItem={invoice => (
                    <List.Item style={{ padding: '16px 0', justifyContent: 'end' }}>
                      <Typography.Text>
                        {formatCurrency(invoice.commissionAmount || 0)} ({row.commissionPercent}%)
                      </Typography.Text>
                    </List.Item>
                  )}
                />
              )}
              sorter={(a: CommissionReportItem, b: CommissionReportItem) =>
                nullableDataSorter(a.invoice[0].commissionAmount, b.invoice[0].commissionAmount)
              }
            />
            <Table.ColumnGroup dataIndex="invoiceNumber" title="Approvals" align="center">
              <Table.Column
                title="Date"
                align="center"
                dataIndex="invoiceNumber"
                render={(_, row: CommissionReportItem) => (
                  <List dataSource={row.invoice} style={{ minWidth: '140px' }} renderItem={invoice => renderInvoiceDatePicker(invoice, row)} />
                )}
              />

              <Table.Column
                title="Status"
                align="center"
                dataIndex="invoiceNumber"
                render={(_, row: CommissionReportItem) => <List dataSource={row.invoice} renderItem={invoice => renderInvoiceButton(invoice, row)} />}
              />

              <Table.Column
                dataIndex="approvedTimesheetCost"
                title="Timesheet cost"
                align="right"
                render={(_, row: CommissionReportItem) => (
                  <List
                    dataSource={row.invoice}
                    renderItem={invoice => (
                      <List.Item style={{ padding: '16px 0', justifyContent: 'end' }}>
                        <Typography.Text>
                          {invoice.approvedTimesheetCost && invoice.approvedTimesheetCost > 0 ? formatCurrency(invoice.approvedTimesheetCost) : ''}
                        </Typography.Text>
                      </List.Item>
                    )}
                  />
                )}
              />
            </Table.ColumnGroup>
          </Table>
        </Form>
      </div>

      {openCommission && ability.can('update', 'Commission') && (
        <ProjectsCommissionModal
          userId={session?.id}
          onCancel={async () => {
            setOpenCommission(false);
            await commissionReportMutate();
          }}
        />
      )}
    </div>
  );
};

export default CommissionReport;

const getStatusRank = (invoice: Invoice | undefined): number => {
  if (!invoice) {
    return 0;
  }
  if (!invoice.isActive) {
    return 1;
  }
  if (invoice.paidDate) {
    return 4;
  }
  const isOverdue = moment(invoice.estimatedPayDate).isBefore(moment());

  return isOverdue ? 3 : 2;
};
