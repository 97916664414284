import axios from 'axios';
import moment from 'moment';
import { displayNumberFromHours } from '../common/utils';
import { CreateCommissionTimesheetEntry, CreateTimesheetEntry } from '../entities';

function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0;
}

function parseMinutes(timeString: any) {
  if (typeof timeString === 'number') {
    if (isFloat(timeString)) {
      let hours = Number(Math.trunc(timeString) * 60);
      let minutes = Math.round((timeString - Math.trunc(timeString)) * 60);
      let result = hours + minutes;
      return result;
    }
    return timeString * 60;
  }

  const timeStringSplit = timeString.split(':');
  if (timeStringSplit.length === 1) {
    let hours = Math.trunc(timeString);
    let minutes = Math.round((timeString - hours) * 60);
    let result = hours * 60 + minutes;
    return result;
  }
  let hours = parseInt(timeStringSplit[0]);
  let minutes = parseInt(timeStringSplit[1]);
  let res = hours * 60 + minutes;
  return res;
}

export async function submitTimesheet(values: CreateTimesheetEntry, id?: string) {
  const ensureCorrectDate = moment(values.entryDate).format(moment.HTML5_FMT.DATE);
  const body = {
    ...values,
    entryDate: ensureCorrectDate,
    project: { id: values.project },
    task: { id: values.task },
    user: { id: values.user },
    hours: values.hours ? displayNumberFromHours(values.hours.toString()) : 0,
    minutes: values.hours ? parseMinutes(values.hours) : 0,
    customRate: values.customRate ? parseFloat(values.customRate.toString()) : 0,
    fixedCost: values.fixedCost ? parseFloat(values.fixedCost.toString()) : 0,
    spentType: undefined,
  };
  if (id) {
    await axios.put(`/timesheets/${id}`, body);
  } else {
    await axios.post(`/timesheets`, body);
  }
}

export const submitCommissionTimesheet = async (values: CreateCommissionTimesheetEntry) => {
  const ensureCorrectDate = moment(values.entryDate).format(moment.HTML5_FMT.DATE);

  const body = {
    ...values,
    entryDate: ensureCorrectDate,
    project: { id: values.project },
    user: { id: values.user },
    fixedCost: values.fixedCost ? parseFloat(values.fixedCost.toString()) : 0,
  };

  await axios.post(`/timesheets/commission`, body);
};
