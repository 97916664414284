import useSWR from 'swr';
import { FetchResult } from '../entities';
import { Invoice } from '../entities/Invoice';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import moment, { isMoment, Moment } from 'moment';

type Result = FetchResult & {
  invoices?: Invoice[];
  saveInvoice: (payload: Invoice) => Promise<AxiosResponse<any>>;
  deleteInvoice: (invoiceId: string) => Promise<AxiosResponse<any>>;
  findInvoiceById: (projectId: string, invoiceId: string) => Promise<AxiosResponse<Invoice>>;
};

type ProjectResult = Result & {
  totalValue?: number;
  totalActualCostWithCommission?: number;
  totalActualCostWithoutCommission?: number;
  totalMarkupPercentage?: number;
};

export function useInvoices(forMonth: string | null): Result {
  const { data, error, mutate } = useSWR<Invoice[]>(`/invoices?forMonth=${forMonth || ''}`);

  const saveInvoice = useCallback((invoice: Invoice) => axios.put(`/projects/${invoice.projectId}/invoices`, formatForSave(invoice)), []);
  const deleteInvoice = useCallback((invoiceId: string) => axios.delete(`/invoices/${invoiceId}`), []);
  const findInvoiceById = useCallback((projectId: string, invoiceId: string) => axios.get(`/projects/${projectId}/invoices/${invoiceId}`), []);

  return {
    invoices: data && formatInvoices(data),
    isLoading: !error && !data,
    isError: error,
    mutate,
    saveInvoice,
    deleteInvoice,
    findInvoiceById,
  };
}

export function useProjectInvoices(projectId: string): ProjectResult {
  const { data, error, mutate } = useSWR<{
    invoices: Invoice[];
    totalValue: number;
    totalActualCostWithoutCommission: number;
    totalActualCostWithCommission: number;
    totalMarkupPercentage: number;
  }>(`/projects/${projectId}/invoices`);

  const saveInvoice = useCallback((invoice: Invoice) => axios.put(`/projects/${projectId}/invoices`, formatForSave(invoice)), [projectId]);
  const deleteInvoice = useCallback((invoiceId: string) => axios.delete(`/projects/${projectId}/invoices/${invoiceId}`), [projectId]);
  const findInvoiceById = useCallback((projectId: string, invoiceId: string) => axios.get(`/projects/${projectId}/invoices/${invoiceId}`), []);

  return {
    invoices: data && formatInvoices(data.invoices),
    totalValue: data && data.totalValue,
    totalActualCostWithCommission: data && data.totalActualCostWithCommission,
    totalActualCostWithoutCommission: data && data.totalActualCostWithoutCommission,
    totalMarkupPercentage: data && data.totalMarkupPercentage,
    isLoading: !error && !data,
    isError: error,
    mutate,
    saveInvoice,
    deleteInvoice,
    findInvoiceById,
  };
}

const formatForSave = (invoice: Invoice) => {
  return {
    ...invoice,
    estimatedPayDate: momentToString(invoice.estimatedPayDate),
    sentDate: momentToString(invoice.sentDate),
    paidDate: momentToString(invoice.paidDate),
    forMonth: isMoment(invoice.forMonth) ? (invoice.forMonth as Moment).startOf('month').format('YYYY-MM-DD') : null,
    dateRange: invoice.dateRange ? invoice.dateRange.map(momentToString) : [],
  };
};

export const formatInvoices = (invoices: Invoice[]): Invoice[] => {
  return invoices.map(invoice => {
    if (invoice.dateRange) {
      return {
        ...invoice,
        details: invoice.details.map(detail => ({
          ...detail,
          phaseName: detail.phaseName || 'Unassigned',
          phase: detail.phaseName || 'Unassigned',
          totalMinutes:
            typeof detail.totalMinutes === 'string'
              ? detail.totalMinutes.split(':').reduce((acc, val, i) => acc + Number(val) * (i === 0 ? 60 : 1), 0)
              : detail.totalMinutes || undefined,
          totalCost: detail?.totalCost && +detail.totalCost,
        })),
        dateRange: [invoice.dateRange[0] ? moment(invoice.dateRange[0]) : null, invoice.dateRange[1] ? moment(invoice.dateRange[1]) : null],
      };
    }
    return invoice;
  });
};

const momentToString = (date: any) => (isMoment(date) ? (date as Moment).format('YYYY-MM-DD') : null);
