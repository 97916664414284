import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { PageHeader, Row, Col, Form, Button, Spin, Tabs, message, Popconfirm, Typography, Badge, notification, Switch } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useProject, useSession, UserWithPermissions, useProjects, useProjectFinance } from '../../dal';
import { useClients } from '../../dal/useClients';
import { useComments } from '../../dal/useComments';
import moment from 'moment';
import ProjectFinances from '../../components/project/ProjectFinance';
import { ExternalLink, Task } from '../../entities';
import ProjectBrief from '../../components/project/ProjectBrief';
import ProjectMembers from '../../components/project/ProjectMembers';
import Links from '../../components/shared/Links';
import Comments from '../../components/shared/Comments';
import TasksTableContainer from '../../components/tasks/TasksTableContainer';
import { useProjectTasks } from '../../dal/useProjectTasks';
import ProjectFinish from '../../components/project/ProjectFinish';
import { useDebouncedCallback } from 'use-debounce';
import ProjectOverview from '../../components/project/ProjectOverview';
import { ProjectPermissions, TasksPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import AddTasksPhase from '../../components/tasks/AddTasksPhase';
import TasksPhase from '../../components/tasks/TasksPhase';
import ProjectDates from '../../components/project/ProjectDates';
import ProjectDetails from '../../components/project/ProjectDetails';
import { useChatContext } from '../../common/chatContext';
import { ReactComponent as TrashIcon } from '../../images/icons/trash-icon.svg';

import './Project.scss';
import ChannelChat from '../../chat/pages/chat/channel-chat';
import { Result } from '../../chat/dal';
import { useFeatureFlag } from 'configcat-react';
import ProjectBudgetPlanner from '../../components/project/ProjectBudgetPlanner';
import { useProjectLinks } from '../../dal/useProjectLinks';
import InvoicePage from '../../components/project/InvoicePage';

const { Paragraph } = Typography;

interface IProps {
  id: string;
  chatData: Result;
}

type UrlParams = {
  id: string;
  tab: string;
  tabId?: string;
};

const Project = (props: IProps) => {
  const { id, chatData } = props;

  const navigate = useNavigate();
  const { project, mutate: mutateProject } = useProject(id!);
  const { links, mutate: mutateLinks } = useProjectLinks(id!);
  const { mutate: mutateProjects } = useProjects();
  const params = useParams<UrlParams>();
  const { id: projectId, tab: projectTab, tabId: projectTabId } = params;
  const { clients } = useClients();
  const { session } = useSession();
  const { hasPermission, ability } = usePermissions();
  const { phases, unassigned, mutate: mutateProjectTasks } = useProjectTasks(id);
  const { comments, mutate } = useComments(id!);
  const [form] = Form.useForm();
  const [projectName, setProjectName] = useState<string>('');
  const [openTab, setOpenTab] = useState<string>('tasks');
  const [initialFormValues, setInitialFormValues] = useState<any>({});
  const { chatUnread } = useChatContext();
  const [isSwitchedManual, setIsSwitchedManual] = React.useState(false);
  const { finance } = useProjectFinance(id);
  const [showNotification, setShowNotification] = useState(true);
  const [isProjectVisibleToClient, setIsProjectVisibleToClient] = useState<boolean>(false);

  const { value: chatEnabled, loading: chatLoading } = useFeatureFlag('chat', false, { identifier: session?.email! });
  const submitProject = useCallback(
    async (values: any) => {
      const result = await axios.put(`/projects/${id}`, values);
      await mutateProject(result.data, false);
      mutateProjects();
      message.success('Project details saved successfully');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, mutateProject],
  );

  const hideOrShowProjectToClient = async (isVisibleToClient: boolean) => {
    setIsProjectVisibleToClient(isVisibleToClient);
    const resp = await axios.patch(`/projects/${id}/hideOrShow`, { isVisibleToClient });
    await mutateProject(resp.data, true);

    const isVisibleMessage = `The project "${project.name}" is ${isVisibleToClient ? 'shown to' : 'hidden for'} the client`;
    message.success(isVisibleMessage);
  };

  const saveNewName = async (name: string) => {
    const result = await axios.put(`/projects/${id}`, { ...form.getFieldsValue(), name });
    await mutateProject(result.data, false);
    mutateProjects();
  };

  const editBrief = async (description: string) => {
    const result = await axios.put(`/projects/${id}`, { ...form.getFieldsValue(), description });
    await mutateProject(result.data, false);
    mutateProjects();
  };

  const submitLinks = async (links: ExternalLink[]) => {
    const result = await axios.put(`/projects/${id}/links`, { links });
    await mutateLinks(result.data, false);
    mutateProjects();
  };

  const submitComment = useCallback(
    async text => {
      await axios.post(`/projects/${id}/comments`, { text });
      await mutate(null, true);
    },
    [id, mutate],
  );

  const editComment = async (text: string, commentId: string) => {
    const result = await axios.patch(`/projects/${id}/comments/${commentId}`, { text });
    await mutate(result.data.data, true);
  };

  const onDelete = async () => {
    await axios.delete(`/projects/${id}`);
    navigate('/projects');
  };

  const handleTaskChange = async (values: Partial<Task>) => {
    const resp = await axios.put(`/tasks/${values.id}`, { ...values, id: undefined }, { params: { projectId: id } });
    await mutateProjectTasks(resp.data);
  };

  const debouncedProjectName = useDebouncedCallback(name => {
    setProjectName(name);
    saveNewName(name);
  }, 1000);

  const openNotification = useCallback(() => {
    const args = {
      message: 'Invoice estimated pay date not filled in',
      description: 'You forgot to fill out the estimated pay date field. Please go to the invoices tab and fill in all estimated pay date fields',
      duration: 0,
    };

    notification.open(args);
  }, []);

  useEffect(() => {
    if (project) {
      setIsSwitchedManual(false);

      setInitialFormValues({
        ...project,
        startDate: project.startDate ? moment(project.startDate) : undefined,
        endDate: project.endDate ? moment(project.endDate) : undefined,
        warrantyEndDate: project.warrantyEndDate ? moment(project.warrantyEndDate) : undefined,
        estimatedEndDate: project.estimatedEndDate ? moment(project.estimatedEndDate) : undefined,
        contractEndDate: project.contractEndDate ? moment(project.contractEndDate) : undefined,
      });

      setIsProjectVisibleToClient(project.isVisibleToClient);

      if (project.name) {
        setProjectName(project.name);
      }
    }
  }, [project]);

  const getChatData = (data: UserWithPermissions, projectId: string): string => {
    return `#chat-id=${process.env.REACT_APP_CHAT_ID}&token=${data.token}&name=${data.name}&picture=${data.picture}&chatView=${projectId}&allow-comment`;
  };

  useEffect(() => {
    if (project && projectId) {
      if (chatEnabled && chatUnread[projectId]?.unreadMentions && !isSwitchedManual) {
        navigate(`/projects/${projectId}/chat`);
        setOpenTab('chat');
      } else if (projectTab) {
        setOpenTab(projectTab);
      }
    }
  }, [projectTab, project, projectId, chatEnabled, chatUnread, isSwitchedManual, navigate]);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [form, initialFormValues]);

  useEffect(() => {
    if (finance) {
      const isAllEstimatedPayDatesFilled = finance.payments.every(payment => payment.estimatedPayDate);

      if (!isAllEstimatedPayDatesFilled && showNotification) {
        openNotification();
        setShowNotification(false);
      }
    }
  }, [finance, openNotification, showNotification]);

  if (!project || !project || !clients || !phases || !unassigned || projectId !== project.id || project.id !== id) return <Spin />;

  return (
    <div id="project-by-id-container">
      <PageHeader
        style={{ marginTop: -4 }}
        title={
          <div style={{ display: 'flex', alignItems: 'center', padding: '0' }}>
            {project.client?.name} •&nbsp;
            <Paragraph
              editable={
                hasPermission(ProjectPermissions.PROJECT_UPDATE)
                  ? { onChange: debouncedProjectName, triggerType: ['icon', 'text'], enterIcon: null }
                  : false
              }
              style={{ display: 'inline-block', margin: '0 10px 0 0' }}
            >
              {projectName}
            </Paragraph>
          </div>
        }
        subTitle={`• ${project.autoCode}`}
        extra={
          <>
            {hasPermission(ProjectPermissions.PROJECT_FINISH) && (
              <>
                <Switch
                  checkedChildren="visible"
                  unCheckedChildren="hidden"
                  checked={isProjectVisibleToClient}
                  onChange={value => hideOrShowProjectToClient(value)}
                />

                <ProjectFinish project={project} hasPermissionForDraft={hasPermission(ProjectPermissions.PROJECT_DRAFT_WRITE)} />
              </>
            )}
            {hasPermission(ProjectPermissions.PROJECT_DELETE) && (
              <Popconfirm title="Are you sure to delete this project?" onConfirm={onDelete} okText="Yes" cancelText="No">
                <Button className="custom-project-button">
                  <TrashIcon />
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />

      <Row style={{ height: 'calc(100% - 50px)' }}>
        <Col span={24} className="h-full rounded-[8px] bg-white">
          <div className="custom-container">
            {projectTab && projectTabId ? (
              <InvoicePage project={project} invoiceId={projectTabId} />
            ) : (
              <Tabs
                activeKey={openTab}
                destroyInactiveTabPane
                onTabClick={key => {
                  setIsSwitchedManual(true);
                  navigate(`/projects/${id}/${key}`);
                  setOpenTab(key);
                }}
                className="h-full"
                items={
                  [
                    hasPermission(TasksPermissions.TASKS_READ) && {
                      key: 'tasks',
                      label: 'Tasks',
                      className: 'padding-sides',
                      children: (
                        <>
                          {phases.map(p => (
                            <TasksPhase phase={p} key={p.id} />
                          ))}
                          {hasPermission(TasksPermissions.TASKS_PHASES_WRITE) && id && <AddTasksPhase projectId={id} />}
                          <TasksTableContainer
                            key={id}
                            title="Unassigned"
                            data={unassigned}
                            initialProjectId={id}
                            personal={false}
                            onTaskChange={handleTaskChange}
                          />
                        </>
                      ),
                    },
                    {
                      key: 'overview',
                      label: 'Overview',
                      className: 'padding-sides',
                      children: (
                        <>
                          <ProjectOverview key={id} project={project} onTabChange={setOpenTab} />
                          <Row gutter={16}>
                            <Col span={12}>
                              <div style={{ marginTop: 16, marginBottom: 16 }}>
                                <ProjectBrief key={id} description={project.description} editBrief={editBrief} />
                              </div>
                              <div style={{ marginTop: 16, marginBottom: 16 }}>
                                <Links key={id} links={links} onSubmit={submitLinks} />
                              </div>

                              <div style={{ marginTop: 16, marginBottom: 16 }}>
                                <ProjectDetails key={id} initialFormValues={initialFormValues} submitProject={submitProject} project={project} />
                              </div>
                            </Col>
                            <Col span={12}>
                              <div style={{ marginTop: 16, marginBottom: 16 }}>
                                <ProjectDates key={id} initialFormValues={initialFormValues} submitProject={submitProject} project={project} />
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={24}>
                              <ProjectMembers key={id} projectId={id!} />
                            </Col>
                          </Row>
                        </>
                      ),
                    },
                    {
                      key: 'comments',
                      label: 'Comments',
                      className: 'padding-sides',
                      children: <Comments key={id} comments={comments} onSubmit={submitComment} onEdit={editComment} userId={session?.id} />,
                    },
                    hasPermission(ProjectPermissions.PROJECT_BUDGET_PLANNER) && {
                      key: 'budget',
                      label: 'Budget planner',
                      className: 'padding-sides',
                      children: <ProjectBudgetPlanner key={id} projectId={id!} />,
                    },
                    {
                      key: 'members',
                      label: 'Members',
                      className: 'padding-sides',
                      children: <ProjectMembers key={id} projectId={id!} />,
                    },
                    ability.can('view', 'Invoice') && {
                      key: 'invoices',
                      label: 'Invoices',
                      className: 'padding-sides',
                      children: <ProjectFinances key={id} project={project} />,
                    },
                    !chatLoading &&
                      projectId && {
                        key: 'chat',
                        label: (
                          <Row wrap={false} align="middle">
                            <Col flex="auto">Chat</Col>
                            <Col
                              flex="none"
                              className={
                                chatUnread[projectId]?.unreadMentions || chatUnread[projectId]?.manualUnread ? 'tab-badges active' : 'tab-badges'
                              }
                            >
                              {!chatUnread[id]?.unreadMentions && <Badge dot={Boolean(chatUnread[projectId]?.manualUnread)} />}
                              <Badge count={chatUnread[projectId]?.unreadMentions} />
                            </Col>
                          </Row>
                        ),
                        className: 'padding-sides',
                        children: chatEnabled ? (
                          session && <ChannelChat idToCheck={projectId} data={getChatData(session, projectId)} upChatData={chatData} />
                        ) : (
                          <div className="flex justify-center items-center text-xl h-full">Coming soon</div>
                        ),
                      },
                  ].filter(Boolean) as []
                }
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Project;
