import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Space,
  Alert,
  Switch,
  Typography,
  List,
  InputNumber,
  Popconfirm,
  PageHeader,
  FormInstance,
} from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { Invoice, InvoiceLineItem, RangeValueType } from '../../entities/Invoice';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { datePickerFormat } from '../../constants';
import MoneyInput from '../shared/MoneyInput';
import InvoiceTable from './InvoiceTable';
import { subject } from '@casl/ability';
import { useProjectCommission } from '../../dal/useProjectCommission';
import { TimesheetSummary, useProjectsExtendedTimesheetSummary } from '../../dal';
import { DataItem, GroupedDataItem } from '../../entities';
import { InvoiceStatusTag } from './InvoiceStatusTag';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ProjectWithClientName } from './ProjectFinance';
import { useNavigate } from 'react-router-dom';
import PDFInvoice from './PDFInovice';
import { useDebouncedCallback } from 'use-debounce';
import { isDeepEqual } from '../../utils';

type Props = {
  project: ProjectWithClientName;
  invoice: Invoice;
  projectBillableRate: number;
  onCancel?: () => void;
  onDelete: (invoiceId: string) => void;
  onSubmit: (invoice: Invoice) => void;
};

const InvoiceForm = ({ project, invoice, projectBillableRate, onCancel, onDelete, onSubmit }: Props) => {
  const [form] = useForm();
  const [dateRange, setDateRange] = useState<RangeValueType | null>(invoice.dateRange);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isActiveInvoice, setIsActiveInvoice] = useState<boolean>(invoice.isActive || false);
  const [groupByPhase, setGroupByPhase] = useState<boolean>(invoice.groupByPhase || false);

  const { ability } = usePermissions();
  const { timesheets } = useProjectsExtendedTimesheetSummary(project.id, dateRange);
  const { commission } = useProjectCommission(project.id);

  const prevTimesheetsRef = useRef<TimesheetSummary[]>([]);

  const { id: projectId, autoCode: projectCode, name: projectName, client } = project;

  const navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
  }, [form, projectId]);

  useEffect(() => {
    const areTimesheetsEqual = isDeepEqual(timesheets, prevTimesheetsRef.current);

    if (areTimesheetsEqual) {
      return;
    }

    const details: InvoiceLineItem[] = form.getFieldValue('details');
    const lineItems = details.filter(detail => detail.isLineItem);

    let updatedDetails: InvoiceLineItem[] = [];

    if (dateRange) {
      if (isInitialLoad) {
        updatedDetails = [
          ...updateInvoiceDetailsWithTimesheet(
            details.filter(detail => !detail.isLineItem),
            timesheets,
          ),
          ...lineItems,
        ];
      } else {
        const detailsWithBillingInfo = updateDetailsBillingInfo(timesheets, projectBillableRate ?? 0);

        updatedDetails = [...detailsWithBillingInfo, ...lineItems];
      }
    } else if (!isInitialLoad) {
      updatedDetails = lineItems;
    }

    updateInvoiceForm(form, updatedDetails);
    prevTimesheetsRef.current = timesheets;
  }, [form, timesheets, isInitialLoad, dateRange, projectBillableRate]);

  const handleValuesChange = useCallback(
    (changedValues: Partial<Invoice>, allValues?: Invoice) => {
      if ('value' in changedValues) {
        const value = changedValues.value;
        const actualCost = allValues?.actualCost || 0;

        if (value && actualCost > 0) {
          const markupPercentage = ((1 - actualCost / value) * 100).toFixed(2);
          form.setFieldValue('markupPercentage', markupPercentage);
        } else {
          form.setFieldValue('markupPercentage', 0);
        }
      }

      if ('isActive' in changedValues) {
        setIsActiveInvoice(changedValues?.isActive || false);
        form.setFieldValue('paidDate', null);
      }

      if ('dateRange' in changedValues) {
        setDateRange(changedValues.dateRange);
      }

      if ('groupByPhase' in changedValues) {
        setGroupByPhase(changedValues?.groupByPhase || false);
      }
    },
    [form],
  );

  const refreshDetails = useCallback(() => {
    const details: InvoiceLineItem[] = form.getFieldValue('details');
    const lineItems = details.filter(detail => detail.isLineItem);

    const detailsWithBillingInfo = updateDetailsBillingInfo(
      timesheets,
      projectBillableRate ?? 0,
      details.filter(detail => !detail.isLineItem),
    );
    const updatedDetails = [...detailsWithBillingInfo, ...lineItems];

    updateInvoiceForm(form, updatedDetails);
    setIsInitialLoad(false);
    prevTimesheetsRef.current = timesheets;
  }, [form, projectBillableRate, timesheets]);

  const handleUpdateLineItems = useDebouncedCallback((lineItems: (DataItem | GroupedDataItem)[]) => {
    const actualInvoice = form.getFieldsValue();

    const updatedDetails = [...actualInvoice.details];

    lineItems.forEach(lineItem => {
      const existingIndex = updatedDetails.findIndex(item => item.key === lineItem.key);

      if (existingIndex !== -1) {
        updatedDetails[existingIndex] = { ...updatedDetails[existingIndex], ...lineItem };
      } else {
        updatedDetails.push(lineItem);
      }
    });

    updateInvoiceForm(form, updatedDetails);
  }, 500);

  const handleDeleteLineItem = useCallback(
    (lineItemKey: string) => {
      const actualInvoice = form.getFieldsValue();
      const updatedDetails = actualInvoice.details.filter(item => item.key !== lineItemKey);

      updateInvoiceForm(form, updatedDetails);
    },
    [form],
  );

  const hasDetails = form.getFieldValue('details')?.length > 0;
  const hasDateRange = useMemo(() => invoice.dateRange?.some(date => date !== null), [invoice.dateRange]);

  const CustomRangePicker = useCallback(({ onChange, ...props }: any) => {
    return (
      <DatePicker.RangePicker
        {...props}
        onChange={dates => {
          setIsInitialLoad(false);
          setDateRange(dates ? [dates[0], dates[1]] : null);
          onChange(dates);
        }}
        renderExtraFooter={() => (
          <Row justify="space-between">
            <Col>
              <Button
                type="link"
                onClick={() => {
                  setIsInitialLoad(false);
                  setDateRange([moment().startOf('month'), moment().endOf('month')]);
                  onChange([moment().startOf('month'), moment().endOf('month')]);
                }}
              >
                This month
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setIsInitialLoad(false);
                  setDateRange([moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]);
                  onChange([moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]);
                }}
              >
                Previous month
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setIsInitialLoad(false);
                  setDateRange([moment().subtract(30, 'days'), moment()]);
                  onChange([moment().subtract(30, 'days'), moment()]);
                }}
              >
                Last 30 days
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setIsInitialLoad(false);
                  setDateRange([moment().startOf('year'), moment().endOf('year')]);
                  onChange([moment().startOf('year'), moment().endOf('year')]);
                }}
              >
                This year
              </Button>
            </Col>

            <Col>
              <Button
                type="link"
                onClick={() => {
                  setIsInitialLoad(false);
                  setDateRange(null);
                  onChange(null);
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        )}
      />
    );
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {!invoice?.isActive && <Alert message="This invoice is a draft (not visible to clients)" type="warning" />}
      {invoice && (
        <>
          <Form
            form={form}
            initialValues={{
              ...invoice,
              estimatedPayDate: invoice.estimatedPayDate ? moment(invoice.estimatedPayDate) : null,
              sentDate: invoice.sentDate ? moment(invoice.sentDate) : null,
              paidDate: invoice.paidDate ? moment(invoice.paidDate) : null,
              forMonth: invoice.forMonth ? moment(invoice.forMonth) : null,
              dateRange: invoice.dateRange ? invoice.dateRange.map(d => (d ? moment(d) : null)) : null,
            }}
            layout="vertical"
            onValuesChange={handleValuesChange}
            onFinish={onSubmit}
          >
            <PageHeader
              onBack={() => navigate(`/projects/${projectId}/invoices`)}
              className="invoice-page-header"
              style={{ padding: '16px 0' }}
              title={
                <Space size="large" align="end">
                  <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    Invoice {invoice?.autoCode}
                  </Typography.Title>

                  {invoice && <InvoiceStatusTag invoice={form.getFieldsValue()} />}

                  <PDFDownloadLink
                    document={<PDFInvoice invoice={form.getFieldsValue()} project={{ projectName, projectCode }} />}
                    fileName={`${invoice.autoCode}-${client?.name}-${projectName}-${projectCode}`}
                  >
                    <Button target="_blank" block icon={<FilePdfOutlined />} type="link">
                      Download
                    </Button>
                  </PDFDownloadLink>
                </Space>
              }
            />

            <Row gutter={16}>
              <Col span={3}>
                <Form.Item label="External #" name="invoiceNumber">
                  <Input disabled={!ability.can('update', subject('Invoice', invoice), 'invoiceNumber')} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Value" name="value">
                  <MoneyInput disabled={!ability.can('update', subject('Invoice', invoice), 'value')} readOnly={hasDetails || hasDateRange} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Actual cost" name="actualCost">
                  <MoneyInput readOnly />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Markup" name="markupPercentage">
                  <InputNumber controls={false} formatter={value => `${value}%`} bordered={false} readOnly />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="For month" name="forMonth">
                  <DatePicker.MonthPicker
                    format="MMMM YYYY"
                    disabled={!ability.can('update', subject('Invoice', invoice), 'forMonth')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Bill for" name="due">
                  <Input disabled={!ability.can('update', subject('Invoice', invoice), 'due')} />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Estimated pay date" name="estimatedPayDate">
                  <DatePicker
                    format={datePickerFormat}
                    disabled={!ability.can('update', subject('Invoice', invoice), 'estimatedPayDate')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Sent date" name="sentDate">
                  <DatePicker
                    format={datePickerFormat}
                    disabled={!ability.can('update', subject('Invoice', invoice), 'sentDate')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Paid date" name="paidDate">
                  <DatePicker
                    format={datePickerFormat}
                    disabled={!ability.can('update', subject('Invoice', invoice), 'paidDate') || !isActiveInvoice}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={20}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Internal note" name="description">
                      <TextArea rows={4} disabled={!ability.can('update', subject('Invoice', invoice), 'description')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Client note" name="clientNote">
                      <TextArea rows={4} disabled={!ability.can('update', subject('Invoice', invoice), 'clientNote')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Internal link" name="invoiceLink" className="invoice-link">
                      <Input.Search
                        enterButton={<Button>Go</Button>}
                        disabled={!ability.can('update', subject('Invoice', invoice), 'invoiceLink')}
                        onSearch={(value: string) => window.open(value, '_blank', 'noopener,noreferrer')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Client link" name="clientInvoiceLink" className="invoice-link">
                      <Input.Search
                        enterButton={<Button>Go</Button>}
                        disabled={!ability.can('update', subject('Invoice', invoice), 'clientInvoiceLink')}
                        onSearch={(value: string) => window.open(value, '_blank', 'noopener,noreferrer')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={4}>
                <Row gutter={16}>
                  <Col span={15}>
                    <Form.Item label="Group by phase?" name="groupByPhase" valuePropName="checked">
                      <Switch disabled={!ability.can('update', subject('Invoice', invoice), 'groupByPhase')} />
                    </Form.Item>
                  </Col>

                  <Col span={9}>
                    <Form.Item label="Is active?" name="isActive" valuePropName="checked">
                      <Switch disabled={!ability.can('update', subject('Invoice', invoice), 'isActive')} />
                    </Form.Item>
                  </Col>
                </Row>

                {ability.can('view', 'Commission') && (
                  <Form.Item label="Commission" name="commission">
                    <List
                      style={{ marginBottom: '24px' }}
                      dataSource={commission?.filter(user => user.value > 0)}
                      renderItem={userWithCommission => (
                        <List.Item>
                          <Row style={{ width: '100%' }} justify="space-between" align="middle">
                            <Col>
                              <Typography.Text>
                                {userWithCommission.userName} <Typography.Text strong>({userWithCommission.value}%)</Typography.Text>
                              </Typography.Text>
                            </Col>

                            <Col>
                              <Typography.Text strong>${((form.getFieldValue('value') * userWithCommission.value) / 100).toFixed(2)}</Typography.Text>
                            </Col>
                          </Row>
                        </List.Item>
                      )}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Row style={{ display: 'flex', justifyContent: 'start', alignItems: 'end' }}>
              <Form.Item label="Line items date range" name="dateRange" style={{ marginBottom: 0 }}>
                <CustomRangePicker />
              </Form.Item>

              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.details !== currentValues.details}>
                {({ getFieldValue }) => {
                  return getFieldValue('details')?.some(detail => detail.newTotalMinutes !== undefined || detail.newTotalCost !== undefined) ? (
                    <Button type="dashed" danger onClick={refreshDetails} style={{ marginLeft: 16 }}>
                      Refresh
                    </Button>
                  ) : null;
                }}
              </Form.Item>
            </Row>

            <Row gutter={8}>
              <Col span={24} className="invoice-table">
                <Form.Item name="details">
                  <InvoiceTable
                    projectId={projectId}
                    projectBillableRate={projectBillableRate ?? 0}
                    onUpdateLineItem={handleUpdateLineItems}
                    onDeleteLineItem={handleDeleteLineItem}
                  />
                </Form.Item>

                <Form.Item name="details">
                  <InvoiceTable isClientView groupByPhase={groupByPhase} />
                </Form.Item>

                <Form.Item name="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="markupPercentage" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="actualCost" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="isActive" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row justify="space-between">
            <Col>
              {invoice.id && onDelete && (
                <Popconfirm
                  title="Do you want to permanently delete this invoice?"
                  onConfirm={() => onDelete(invoice.id!)}
                  disabled={!ability.can('delete', subject('Invoice', subject('Invoice', invoice)))}
                >
                  <Button type="link" danger disabled={!ability.can('delete', subject('Invoice', invoice))}>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </Col>
            <Col>
              {onCancel && (
                <Button type="text" onClick={onCancel}>
                  Cancel
                </Button>
              )}

              <Button key="save" type="primary" disabled={!ability.can('create', subject('Invoice', invoice))} onClick={form.submit}>
                Save
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Space>
  );
};

const calculateInvoiceValues = (details: InvoiceLineItem[]) => {
  const actualValue = details.reduce((acc, { billableTotal = 0, fixedBillableTotal = 0 }) => acc + (fixedBillableTotal || billableTotal), 0);

  const actualCost = details.filter(({ isCommission }) => !isCommission).reduce((acc, { totalCost = 0 }) => acc + totalCost, 0);

  const actualMarkup = actualValue > 0 ? (1 - actualCost / actualValue) * 100 : 0;

  return { actualValue, actualCost, actualMarkup };
};

const updateInvoiceForm = (form: FormInstance<any>, details: InvoiceLineItem[]) => {
  const { actualValue, actualCost, actualMarkup } = calculateInvoiceValues(details);

  form.setFieldsValue({
    ...form.getFieldsValue(),
    value: Number(actualValue.toFixed(2)),
    actualCost: Number(actualCost.toFixed(2)),
    markupPercentage: Number(actualMarkup.toFixed(2)),
    details,
  });
};

const updateDetailsBillingInfo = (items: any[], globalBillableRate: number, details?: InvoiceLineItem[]) =>
  items.map(item => {
    const billableHours = item.totalMinutes / 60;
    const billableTotal = billableHours * (globalBillableRate ?? 0);

    if (details && details.length > 0) {
      const matchingDetail = details.find(detail => detail.key.replace(/^\d*_* *_*/, '') === item.key.replace(/^\d*_* *_*/, ''));
      if (matchingDetail) {
        return {
          ...item,
          billableHours: matchingDetail.billableHours,
          billableRate: matchingDetail.billableRate,
          billableTotal: matchingDetail.billableTotal,
          fixedBillableTotal: matchingDetail.fixedBillableTotal,
        };
      }
    }

    return {
      ...item,
      billableHours: item.billableHours ?? billableHours,
      billableRate: item.billableRate ?? (item.isCommission || item.fixedCost ? item.billableRate : globalBillableRate ?? 0),
      billableTotal: item.billableTotal ?? (item.isCommission ? 0 : billableTotal),
      fixedBillableTotal:
        item.fixedBillableTotal ?? (item.isCommission ? 0 : item.fixedCost ? item.fixedBillableTotal || item.fixedCost || billableTotal : 0),
    };
  });

const updateInvoiceDetailsWithTimesheet = (invoiceDetails: InvoiceLineItem[], timesheets: TimesheetSummary[]) => {
  const existingItems = invoiceDetails.map(item => {
    const updatedItem = timesheets.find(t => t.key.includes(item.key.replace(/^\d*_* *_*/, '')));

    if (updatedItem) {
      return {
        ...item,
        ...(updatedItem.totalMinutes !== item.totalMinutes && { newTotalMinutes: Number(updatedItem.totalMinutes) }),
        ...(updatedItem.totalCost !== item.totalCost && { newTotalCost: updatedItem.totalCost }),
      };
    }

    return {
      ...item,
      newTotalMinutes: 0,
      newTotalCost: 0,
    };
  });

  // TODO: perhaps it can be added in the future
  // const newItems = timesheets.filter(item => !invoiceDetails.some(oldItem => item.key.includes(oldItem.key))).map(item => ({ ...item, isNew: true }));
  // return [...existingItems, ...newItems];

  return existingItems;
};

export default InvoiceForm;
