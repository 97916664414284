import { Modal, Space } from 'antd';
import { Invoice } from '../../entities/Invoice';
import InvoiceForm from './InvoiceForm';
import { ProjectWithClientName } from './ProjectFinance';

type Props = {
  project: ProjectWithClientName;
  invoice: Invoice;
  projectBillableRate: number;
  onCancel: () => void;
  onDelete: (invoiceId: string) => void;
  onSubmit: (invoice: Invoice) => void;
};

const InvoiceModal = ({ project, invoice, projectBillableRate, onCancel, onDelete, onSubmit }: Props) => (
  <Modal open={invoice !== null} onCancel={() => onCancel()} width="min(calc(100% - 40px), 1400px)" style={{ top: 20 }} destroyOnClose footer={[]}>
    <Space direction="vertical" style={{ width: '100%' }}>
      {invoice && (
        <InvoiceForm invoice={invoice} project={project} projectBillableRate={projectBillableRate} onSubmit={onSubmit} onDelete={onDelete} />
      )}
    </Space>
  </Modal>
);

export default InvoiceModal;
