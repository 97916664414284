import { ReactNode } from 'react';
import { FIGMA_URL, GOOGLE_DOCS_URL, GOOGLE_SHEETS_URL, PANDA_DOC_URL, XERO_URL } from './constants';
import { DataItem, GroupedDataItem } from '../entities';
import { ReactComponent as IconFigma } from '../images/icons/icon-figma.svg';
import { ReactComponent as IconDocs } from '../images/icons/icon-google-docs.svg';
import { ReactComponent as IconSheets } from '../images/icons/icon-google-sheets.svg';
import { ReactComponent as IconXero } from '../images/icons/icon-xero.svg';
import { ReactComponent as IconPandadoc } from '../images/icons/icon-pandadoc.svg';
import { ReactComponent as IconFile } from '../images/icons/file-gray.svg';

const iconMap: Record<string, ReactNode> = {
  [FIGMA_URL]: <IconFigma />,
  [GOOGLE_DOCS_URL]: <IconDocs />,
  [GOOGLE_SHEETS_URL]: <IconSheets />,
  [XERO_URL]: <IconXero />,
  [PANDA_DOC_URL]: <IconPandadoc />,
};

export const getIcon = (url: string): ReactNode => {
  const matchingKey = Object.keys(iconMap).find(key => url.includes(key));
  return matchingKey ? iconMap[matchingKey] : <IconFile />;
};

export const getInitials = (name: string) => {
  const words = name.trim().split(' ');
  const initials = words.map((word, index) => (index === 0 || index === words.length - 1 ? word.charAt(0).toUpperCase() : '')).join('');
  return initials;
};

export const deepMerge = (oldData, newData) => {
  for (const key in newData) {
    if (typeof newData[key] === 'object' && !Array.isArray(newData[key])) {
      if (!oldData[key]) {
        oldData[key] = {};
      }
      deepMerge(oldData[key], newData[key]);
    } else {
      oldData[key] = newData[key];
    }
  }
  return oldData;
};

export const formInvoiceFromTimesheetData = (data: DataItem[], groupingOption?: string): (DataItem | GroupedDataItem)[] => {
  if (!groupingOption) {
    return data;
  }

  const groupedMap = new Map<string, { group: GroupedDataItem; hourlyItems: DataItem[]; fixedCostItems: DataItem[]; lineItemFixed: DataItem[] }>();

  data?.forEach(item => {
    if (!item) {
      return;
    }

    const groupKey = item[groupingOption as keyof DataItem] || 'Undefined';

    if (!groupedMap.has(groupKey as string)) {
      groupedMap.set(groupKey as string, {
        group: {
          key: groupKey as string,
          totalCost: 0,
          totalMinutes: 0,
          billableHours: 0,
          billableRate: item.billableRate || 0,
          billableTotal: 0,
          fixedCost: 0,
          fixedBillableTotal: 0,
          isGroup: true,
          type: 'hourly',
        },
        hourlyItems: [],
        fixedCostItems: [],
        lineItemFixed: [],
      });
    }

    const groupData = groupedMap.get(groupKey as string)!;

    if (item.isLineItem && item.type === 'fixed') {
      groupData.lineItemFixed.push(item);
    } else if (item.fixedCost) {
      groupData.group.fixedCost = (groupData.group.fixedCost || 0) + (item.isCommission ? 0 : item.fixedCost || 0);
      groupData.fixedCostItems.push(item);
    } else {
      groupData.group.totalCost = (groupData.group.totalCost || 0) + (item.isCommission ? 0 : item.totalCost || 0);
      groupData.group.totalMinutes = (groupData.group.totalMinutes || 0) + Number(item.totalMinutes || 0);
      groupData.group.billableHours = (groupData.group.billableHours || 0) + (item.billableHours || 0);
      groupData.group.billableTotal = (groupData.group.billableTotal || 0) + (item.billableTotal || 0);
      groupData.hourlyItems.push(item);
    }
  });

  const result: (DataItem | GroupedDataItem)[] = [];
  groupedMap.forEach(({ group, hourlyItems, fixedCostItems, lineItemFixed }) => {
    if (hourlyItems.length === 0 && fixedCostItems.length === 0 && lineItemFixed.length === 0) {
      return;
    }

    if (fixedCostItems.length || lineItemFixed.length) {
      const fixedCostGroup: GroupedDataItem = {
        key: `${group.key} (fixed cost)`,
        totalCost: group.fixedCost,
        isGroup: true,
        type: 'fixed',
        totalMinutes: 0,
        billableHours: lineItemFixed.length ? lineItemFixed.reduce((sum, item) => sum + (item.billableHours || 0), 0) : 0,
        billableRate: lineItemFixed.length ? lineItemFixed.reduce((sum, item) => sum + (item.billableRate || 0), 0) : 0,
        billableTotal: 0,
        fixedCost: group.fixedCost,
        fixedBillableTotal: lineItemFixed.reduce(
          (sum, item) => sum + (item.billableTotal || 0),
          fixedCostItems.reduce((sum, item) => sum + (item.fixedBillableTotal || 0), 0),
        ),
      };
      result.push(fixedCostGroup);
      result.push(...fixedCostItems);
      result.push(...lineItemFixed);
    }

    if (hourlyItems.length > 0) {
      result.push(group);
      result.push(...hourlyItems);
    }
  });

  return result;
};

export const convertToTitleCase = (str: string) => (!str ? '' : str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()));

export const calculateGroupSum = (
  customValues: Record<string, DataItem | GroupedDataItem>,
  groupKey: string,
  type: 'hours' | 'spend' | 'fixed' | 'count',
): number => {
  return Object.entries(customValues).reduce((sum, [key, value]) => {
    const cleanGroupKey = groupKey.replace(' (fixed cost)', '');

    const isGroupMatch =
      (key.includes(groupKey) && !key.endsWith('fixedCost') && !value.isGroup) ||
      ((value.category === groupKey || value.phaseName === groupKey) && value.type === 'hourly');

    const isFixedGroupMatch =
      (key.includes(cleanGroupKey) && key.endsWith('fixedCost') && !value.isGroup) ||
      ((value.category === cleanGroupKey || value.phaseName === cleanGroupKey) && value.type === 'fixed');

    if (type === 'hours' && isGroupMatch) {
      return sum + (!value.isCommission ? value.billableHours || 0 : 0);
    }

    if (type === 'spend' && isGroupMatch) {
      return sum + (value.billableHours || 0) * (value.billableRate || 0);
    }

    if (type === 'fixed' && isFixedGroupMatch) {
      return sum + (value.fixedBillableTotal || value.billableTotal || 0);
    }

    if (type === 'count' && isFixedGroupMatch) {
      return sum + (value.billableHours || 0);
    }

    return sum;
  }, 0);
};

export const isDeepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
