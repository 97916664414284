type Result = { roles: string[] };

export function useMembersRoles(): Result {
  return {
    roles: [
      'Account Executive',
      'Ad Specialist / Marketer',
      'Animator',
      'Assistant',
      'Backend Developer (apps)',
      'Consultant',
      'Content Writer',
      'Creative Director',
      'Designer (UI/UX)',
      'Dev Lead',
      'Financial Executive',
      'Frontend Developer (HTML)',
      'Illustrator (logo / graphic design)',
      'Legal',
      'Photographer / Videographer',
      'Product Owner',
      'Project Manager',
      'QA',
      'SEO',
      'Social Media Manager',
      'Video Editor',
      'WordPress Developer',
      'Undefined',
    ],
  };
}
