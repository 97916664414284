import { useState, useEffect, useCallback } from 'react';
import { message, Space, Spin } from 'antd';
import InvoiceForm from './InvoiceForm';
import { Invoice } from '../../entities/Invoice';
import { formatInvoices, useProjectInvoices } from '../../dal/useProjectInvoices';
import { useProjectFinance } from '../../dal';
import { useNavigate } from 'react-router-dom';
import { ProjectWithClientName } from './ProjectFinance';

type Props = {
  project: ProjectWithClientName;
  invoiceId: string;
};

const InvoicePage = ({ project, invoiceId }: Props) => {
  const navigate = useNavigate();
  const { id: projectId } = project;

  const { mutate: mutateInvoice, findInvoiceById, deleteInvoice, saveInvoice } = useProjectInvoices(projectId);
  const { finance } = useProjectFinance(projectId);

  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (invoiceId === 'newInvoice') {
      const draftInvoice = {
        ordinal: 0,
        autoCode: '',
        invoiceNumber: '',
        value: 0,
        actualCost: 0,
        paid: false,
        description: '',
        clientNote: '',
        invoiceLink: '',
        clientInvoiceLink: '',
        due: '',
        estimatedPayDate: null,
        sentDate: null,
        paidDate: null,
        isActive: false,
        dateRange: null,
        details: [],
        markupPercentage: 0,
        groupByPhase: false,
      };

      setInvoice(draftInvoice);
    } else {
      findInvoiceById(projectId, invoiceId).then(data => {
        setInvoice(formatInvoices([data.data])[0]);
        setLoading(false);
      });
    }

    setLoading(false);
  }, [findInvoiceById, invoiceId, projectId]);

  const onInvoiceDelete = useCallback(
    async (invoiceId: string) => {
      navigate(`/projects/${projectId}/invoices`);

      await deleteInvoice(invoiceId);
      await mutateInvoice();

      message.success('Your invoice has been deleted');
    },
    [deleteInvoice, mutateInvoice, navigate, projectId],
  );

  const onInvoiceFormFinish = useCallback(
    async (invoice: Invoice) => {
      const savedInvoice = await saveInvoice(invoice);

      if (savedInvoice.data.createdAt) {
        navigate(`/projects/${projectId}/invoices/${savedInvoice.data.id}`);
      }

      await mutateInvoice();

      message.success('Your invoice has been saved');
    },
    [mutateInvoice, navigate, projectId, saveInvoice],
  );

  if (loading || !finance || !projectId) {
    return <Spin />;
  }

  return (
    <Space
      style={{
        padding: '20px',
        height: '100%',
        overflow: 'auto',
      }}
      direction="vertical"
    >
      {invoice && (
        <InvoiceForm
          invoice={invoice}
          projectBillableRate={finance.billableRate}
          project={project}
          onSubmit={onInvoiceFormFinish}
          onDelete={onInvoiceDelete}
        />
      )}
    </Space>
  );
};

export default InvoicePage;
