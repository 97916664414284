import { Input, Modal, Select, Spin, Form } from 'antd';
import { useClient, useClients, useProjects } from '../../dal';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Props {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  client?: string;
  dontRedirect?: boolean;
}

const AddProjectForm = ({ modalOpen, setModalOpen, client, dontRedirect }: Props) => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { clients } = useClients();
  const { mutate: mutateClient } = useClient(client);
  const { mutate } = useProjects();
  const clientsList = clients && clients.map(c => ({ label: c.name, value: c.id }));

  const onFinish = async (values: { name: string; client: string }) => {
    const result = await axios.post(`/projects`, { name: values.name, client: values.client });
    await mutate(null, true);
    await mutateClient(null, true);
    setTimeout(() => {
      navigate(`/projects/${result.data.data.id}`);
      setModalOpen(false);
    }, 1);
  };

  if (!clients) return <Spin />;

  return (
    <Modal title="Add project" open={modalOpen} onOk={() => form.submit()} onCancel={() => setModalOpen(false)}>
      <Form form={form} onFinish={onFinish} initialValues={{ client: client ? client : undefined }}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
          <Input placeholder="Enter new project name" />
        </Form.Item>
        <Form.Item label="Client" name="client" rules={[{ required: true, message: 'Client is required' }]} hidden={!!client}>
          <Select
            placeholder="Select client"
            showSearch
            style={{ width: '100%' }}
            options={clientsList}
            filterOption={(input, option) => {
              return `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {clients.map(c => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProjectForm;
