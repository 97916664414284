import useSWR from 'swr';
import { FetchResult, CommissionReportItem } from '../entities';

type TotalCommissionAmount = {
  total: {
    billAmount: number;
    commissionAmount: number;
    commissionAmountApproved: number;
  };
  persons: {
    personName: string;
    billAmount: number;
    commissionAmount: number;
    commissionAmountApproved: number;
  }[];
};

type Result = FetchResult & {
  commissionReportForMonth: CommissionReportItem[];
  totalCommissionAmountYearAll: TotalCommissionAmount;
  totalCommissionAmountMonthAll: TotalCommissionAmount;
};

export function useCommissionReport(forMonth: string | null): Result {
  const { data, error, mutate } = useSWR(`/commission?forMonth=${forMonth || ''}`);

  const groupedData: CommissionReportItem[] =
    data?.data &&
    Object.values(
      data?.data.reduce((acc, item) => {
        const key = `${item.projectId}-${item.forMonth}-${item.personName}`;

        if (!acc[key]) {
          acc[key] = {
            key,
            projectCode: item.projectCode,
            clientName: item.clientName,
            clientId: item.clientId,
            projectName: item.projectName,
            commissionPercent: item.commissionPercent,
            personName: item.personName,
            projectId: item.projectId,
            forMonth: item.forMonth,
            userId: item.userId,
            invoice: [],
          };
        }

        acc[key].invoice.push({
          invoiceNumber: item.invoiceNumber,
          id: item.invoiceId,
          isActive: item.invoiceIsActive,
          paidDate: item.invoicePaidDate,
          sentDate: item.invoiceSentDate,
          estimatedPayDate: item.invoiceEstimatedPayDate,
          value: item.billAmount,
          commissionAmount: item.commissionAmount,
          isApproved: item.isApproved,
          approveDate: item.approveDate,
          approvedTimesheetCost: item.approvedTimesheetCost,
        });

        return acc;
      }, {}),
    );

  return {
    commissionReportForMonth: groupedData,
    totalCommissionAmountYearAll: data?.totalCommissionAmountYearAll,
    totalCommissionAmountMonthAll: data?.totalCommissionAmountMonthAll,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
