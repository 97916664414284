import moment from 'moment';
import { datePickerFormat } from '../constants';
import { ApprovalStatus, ApprovalType, Timesheet, User, UserRates, HomepageSettings, HomepageSetting } from '../entities';

export function round2(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function getFirstDayOfMonth() {
  return moment().startOf('month').format('YYYY-MM-DD');
}

export function isLastDayOfMonth(d: Date) {
  return new Date(d.getTime() + 86400000).getDate() === 1;
}

export function getLastSaturday() {
  const d = new Date();
  d.setDate(d.getDate() - ((d.getDay() + 6) % 7)); // Monday of this week
  const saturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 2);
  return moment(saturday.toDateString()).format('YYYY-MM-DD');
}

export function formatDateTime(date: string | Date, format = 'lll'): string | undefined {
  return date ? moment(date).format(format) : undefined;
}

export function formatDate(date: string | Date, format = datePickerFormat): string | undefined {
  return date ? moment(date).format(format) : undefined;
}

export function formatCurrency(value: number): string {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value || 0);
}

export function getWeekDays(startDate?: string): string[] {
  let days: string[] = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(startDate).add(i, 'days').format(datePickerFormat));
  }

  return days;
}

export function displayNumberFromHours(timeString: string): number {
  if (typeof timeString === 'number') return timeString;

  const timeStringSplit = timeString.split(':');
  if (timeStringSplit.length === 1) {
    return Number(timeString);
  }
  const dec = parseInt(((Number(timeStringSplit[1]) / 6) * 10).toString(), 10);

  return parseFloat(parseInt(timeStringSplit[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}

export function displayHoursFromNumber(hours: number): string {
  const hour = Math.floor(hours);
  const decimal = hours - hour;
  const min = 1 / 60;
  const calculateDecimal = min * Math.round(decimal / min);
  const calculateMinute = Math.floor(calculateDecimal * 60);

  return [
    hour.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
    calculateMinute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
  ].join(':');
}

export function displayDurationFromMinutes(totalMinutes: number): string {
  totalMinutes = +totalMinutes;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return [
    hours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
    minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
  ].join(':');
}

export function getTotalHours(timesheets?: Timesheet): number {
  return timesheets?.reduce((total, { hours }) => total + hours, 0) ?? 0;
}

export function getTotalCost(timesheets?: Timesheet): number {
  return timesheets?.reduce((total, { cost }) => total + cost, 0) ?? 0;
}

export function getTotalAmount(timesheets?: Timesheet, rates?: UserRates | User): number {
  const monthlyAmount = rates?.rateType === 'monthly' ? rates.rateValue ?? 0 : 0;
  const timesheetAmount = timesheets?.reduce((total, { amount }) => total + amount, 0) ?? 0;
  return timesheetAmount + monthlyAmount;
}

export function buildTimesheetQueryParams(startDate?: string, monthlyView?: boolean): string {
  if (!startDate) {
    return '';
  }

  const usp = new URLSearchParams({ startDate, monthlyView: monthlyView ? 'true' : 'false' });
  usp.sort();
  const queryString = usp.toString();
  return queryString;
}

export function nullableDataSorter(a?: Date | string | null | number, b?: Date | string | null | number): number {
  if (a !== null && b !== null && a !== undefined && b !== undefined) {
    if (a instanceof Date && b instanceof Date) {
      return a.getTime() - b.getTime();
    } else if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b);
    } else if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
  }

  if (a !== null && a !== undefined) {
    return -1;
  } else if (b !== null && b !== undefined) {
    return 1;
  }

  return 0;
}

export function getApprovalStatus(approval: ApprovalType): ApprovalStatus {
  if (!approval) {
    return ApprovalStatus.READY_TO_SUBMIT;
  }

  if (approval.approvedAt) {
    return ApprovalStatus.APPROVED;
  }

  if (approval.rejectedAt && new Date(approval.rejectedAt) > new Date(approval.submittedAt)) {
    return ApprovalStatus.REJECTED;
  }

  if (approval.rejectedAt && approval.submittedAt && new Date(approval.rejectedAt) < new Date(approval.submittedAt)) {
    return ApprovalStatus.AWAITING_REAPPROVAL;
  }

  if (approval.submittedAt !== null && approval.rejectedAt === null) {
    return ApprovalStatus.AWAITING_APPROVAL;
  }

  return ApprovalStatus.READY_TO_SUBMIT;
}

export function sortHomepageSettingsByDate(data: HomepageSettings): HomepageSettings {
  return data.sort(function (a, b) {
    return new Date(a.dateFrom as Date).getTime() - new Date(b.dateFrom as Date).getTime();
  });
}

export function getCurrentHomepageSettings(data: HomepageSettings): HomepageSetting | undefined {
  const filter = sortHomepageSettingsByDate(data)
    .filter(d => moment(d.dateFrom).isSameOrBefore(new Date()))
    .sort((a, b) => moment(a.dateFrom).toDate().getTime() - moment(b.dateFrom).toDate().getTime())
    .reverse();
  return filter[0];
}
