import { useState } from 'react';
import { Button, Radio } from 'antd';
import { Task, TaskPhase } from '../../entities';
import TasksTable from './TasksTable';
import './TaskTable.css';
import PhaseHeader from './PhaseHeader';

type Filter = 'todo' | 'all';

const sortByProject = (tasks: Task[]): Array<{ projectName: string; tasks: Task[] }> => {
  if (!tasks) return [];
  const projects = tasks.reduce((prev, curr) => {
    const name = curr?.project?.name;
    if (name && !prev.includes(name)) {
      return [...prev, name];
    }
    return prev;
  }, [] as string[]);
  const result = projects.map(p => {
    const filteredTasks = tasks.filter(t => t.project?.name === p);
    return { projectName: p, tasks: filteredTasks };
  });
  return result;
};

const filterTasks = (tasks: Task[] = [], filter: Filter): Task[] =>
  tasks.filter(task => task.project && (filter !== 'todo' || task.status === 'todo'));

const TasksTableContainer = ({
  data,
  initialProjectId,
  initialPhaseId,
  initialTicketId,
  title,
  phase,
  personal,
  showProjectSortOption,
  userId,
  onTaskChange,
}: {
  data: Task[];
  initialProjectId?: string;
  initialPhaseId?: string;
  initialTicketId?: string;
  title?: string;
  phase?: TaskPhase;
  personal: boolean;
  showProjectSortOption?: boolean;
  userId?: number;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
}) => {
  const [filter, setFilter] = useState<Filter>('todo');
  const [view, setView] = useState<'all' | 'byProject'>('all');
  const filteredTasks = filterTasks(data, filter);

  return (
    <>
      <div className="tasks-table-header">
        <div style={{ width: '100%' }}>
          {title && <h4>{title}</h4>}
          {phase && <PhaseHeader phase={phase} tasks={filteredTasks} />}
        </div>
        <div className="tasks-table-header-wrap" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexShrink: 0 }}>
          {showProjectSortOption && (
            <Button onClick={() => setView(v => (v === 'all' ? 'byProject' : 'all'))} style={{ marginRight: '20px' }}>
              {view === 'all' ? 'Sort by project' : 'Show all'}
            </Button>
          )}
          <Radio.Group onChange={e => setFilter(e.target.value)} value={filter}>
            <Radio value="todo">Todo</Radio>
            <Radio value="all">All</Radio>
          </Radio.Group>
        </div>
      </div>

      {view === 'all' && (
        <div style={{ borderColor: '#ddd', borderWidth: '0px 1px 1px 1px', borderStyle: 'solid' }}>
          <TasksTable
            data={filteredTasks}
            initialProjectId={initialProjectId}
            initialPhaseId={initialPhaseId}
            initialTicketId={initialTicketId}
            personal={personal}
            userId={userId}
            onTaskChange={onTaskChange}
          />
        </div>
      )}

      {view === 'byProject' &&
        sortByProject(data).map(i => (
          <div style={{ marginBottom: '40px' }} key={i.projectName}>
            <h4 style={{ padding: '8px', margin: 0, background: '#fafafa' }}>
              Project: {i.tasks[0].project?.client?.name} - {i.projectName}
            </h4>
            <TasksTable data={filterTasks(i.tasks, filter)} personal={false} userId={userId} onTaskChange={onTaskChange} />
          </div>
        ))}
    </>
  );
};

export default TasksTableContainer;
