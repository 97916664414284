import { useCallback, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Modal, Space, Typography, List, InputNumber, Spin, message } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { Commission } from '../../entities';
import ProjectSelector from '../../components/project/ProjectSelector';
import { useUserCommission } from '../../dal/useUserCommission';

type Props = {
  userId?: number;
  onCancel: () => void;
};

const ProjectsCommissionModal = ({ userId, onCancel }: Props) => {
  const [form] = useForm();

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const { commission = [], mutate, isLoading, saveCommission, deleteCommission } = useUserCommission(userId);

  const submitCommission = useCallback(
    async (commission: Commission) => {
      await saveCommission(commission);
      await mutate();
      form.resetFields();
    },
    [form, mutate, saveCommission],
  );

  const onDelete = useCallback(
    async id => {
      await deleteCommission(id);
      await mutate();
      message.success('Commission successfully removed');
    },
    [deleteCommission, mutate],
  );

  const handleAddProject = useCallback(
    async (values: { project: string }) => {
      const existingProject = commission.find(item => item.projectId === values.project);

      if (existingProject) return;

      const newProject = { projectId: values.project, value: 0, userId } as Commission;

      submitCommission(newProject);
      mutate();
    },
    [commission, mutate, userId, submitCommission],
  );

  const handleValueChange = useCallback(
    (projectId: string, value: number) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const existingProject = commission.find(project => project.projectId === projectId);

        if (existingProject) {
          submitCommission({ ...existingProject, value });
          message.success('Commission updated');
        }
      }, 500);
    },
    [commission, submitCommission],
  );

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <Modal
      open={!!commission}
      title={<Typography.Text>Project commission</Typography.Text>}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key="close" onClick={onCancel}>
          Close
        </Button>,
      ]}
      width={800}
    >
      <Spin spinning={isLoading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form form={form} layout="vertical" onFinish={handleAddProject}>
            <Row gutter={16} align="middle">
              <Col span={20}>
                <Form.Item label="Project" name="project">
                  <ProjectSelector hideClosed={true} excludeIds={commission.map(commissionItem => commissionItem.projectId)} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button icon={<PlusOutlined />} htmlType="submit" type="link" style={{ padding: 0 }}>
                  Add
                </Button>
              </Col>
            </Row>
          </Form>

          <List
            dataSource={commission}
            renderItem={projectWithCommission => (
              <List.Item
                key={projectWithCommission.id}
                actions={[<Button icon={<CloseOutlined />} type="text" onClick={() => onDelete(projectWithCommission.id)} />]}
              >
                <List.Item.Meta
                  title={`${projectWithCommission.clientName} - ${projectWithCommission.projectName} - ${projectWithCommission.projectCode}`}
                />
                <InputNumber
                  min={0}
                  max={100}
                  controls={false}
                  formatter={value => `${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  value={projectWithCommission.value}
                  onChange={value => handleValueChange(projectWithCommission.projectId, value || 0)}
                />
              </List.Item>
            )}
          />
        </Space>
      </Spin>
    </Modal>
  );
};

export default ProjectsCommissionModal;
