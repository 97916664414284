import { Select } from 'antd';
import { useProjectTasks } from '../../dal/useProjectTasks';

const PhaseSelector = (props: any) => {
  const { phases = [], unassigned = [], isLoading } = useProjectTasks(props.projectid);

  const options = [
    ...(phases?.map(phase => ({ label: phase.name, value: phase.name })) ?? []),
    ...(props.isLineItem ? [{ label: 'Undefined', value: 'Undefined' }] : []),
    ...(unassigned.length > 0 ? [{ label: 'Unassigned', value: 'Unassigned' }] : []),
  ];

  if (!props.projectid && isLoading) {
    return <>Project not selected</>;
  }

  if (!isLoading && options?.length === 0) {
    return (
      <>
        No phases in this <a href={`/projects/${props.projectid}`}>project</a>
      </>
    );
  }

  return (
    <Select
      {...props}
      showSearch
      allowClear
      placeholder="Select phase"
      options={options}
      filterOption={(input, option) => option?.label?.toString().toLowerCase().includes(input.toLowerCase())}
    />
  );
};

export default PhaseSelector;
