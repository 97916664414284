import { Select, SelectProps } from 'antd';
import { useMembersRoles } from '../../dal';

const RoleSelector = (props: SelectProps) => {
  const { roles } = useMembersRoles();
  return (
    <Select {...props} placeholder="Select role" showSearch allowClear>
      {roles.map((role: any) => {
        return (
          <Select.Option key={role} value={role}>
            {role}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default RoleSelector;
